.AccordionSection {
}
.SupportBar {
  background: var(--color-pfs-blue-hover);
  border-radius: 1.125em;
  display: flex;
  margin-top: 2em;
  justify-content: space-between;
  align-items: center;
  height: 5em;
  cursor: pointer;
}
.SupportBar div {
  margin: 0;
  display: flex;
  align-items: center;
}

.WhiteFont {
  color: white;
  font: normal normal 200 1.125em/1.56em Raleway;
}
.SupportBar span {
  margin-left: 1em;
  text-align: left;
}

.SupportBar img:first-child {
  margin-left: 1.5em;
  height: 2.5em;
}
.SupportBar img:last-child {
  height: 1.75em;
  margin-right: 1.5em;
}
.Closed {
  background: white;
  box-shadow: 0.375em 0.375em 1.5em #e3e3e3;
}
.Closed span {
  color: var(--color-pfs-blue);
}
.icon {
  margin-right: 1em;
  padding: 0.08em;
  height: 0.75em;
  width: 0.75em;
  background-color: var(--color-pfs-blue);
  border-radius: 50%;
  color: #ffffff;
}
.image {
  display: inline-flex;
  width: 3em;
  margin: 0 0.5em;
}
.Loader {
  border: 0.5em solid var(--color-pfs-light-gray);
  border-top: 0.5em solid var(--color-pfs-blue);
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 3em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
