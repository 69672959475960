.PFSServicesBanner {
    margin: 1.618em 0em
}

#TitleText {
    text-align: center;
    font-size: 3.875em;
    padding: 0;
    margin: 0;
}

#CompanyTitle {
    font-weight: 900;
}

#CompanySubtitle {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.85em;
}

.ServiceCardContainer {
    display: inline-block;
    width: 33%;
    margin-bottom: 2.5em;
}

@media screen and (max-width: 45em) {
    .ServiceCardContainer {
        width: 50%;
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 35.5em) {
    .ServiceCardContainer {
        width: 95%;
        margin-bottom: 1em;
    }
}